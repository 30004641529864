export const environment = {
  env: 'PROD',
  cookieDomain: 'maplix.com',
  production: true,
  apps: {
    root: 'https://www.maplix.com/map/',
    auth: 'https://www.maplix.com/account/',
    home: 'https://www.maplix.com/dashboard/',
    editor: 'https://www.maplix.com/editor/',
    surveyViewer: 'https://www.maplix.com/survey/',
    mapViewer: 'https://www.maplix.com/map/',
  },
  api: `https://api.maplix.com/`,
  stitchId: 'maplix-account-prod-ukpqz',
  geoserver: 'https://geo.maplix.com/geoserver/',
  geoserverDb: 'maplix-prod',
  mapboxToken: 'pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw',
  plausible: {
    domain: 'maplix.com',
  },
};
